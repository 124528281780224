import { useEffect, useState } from "react";
import {
  Candidate,
  CandidateStatus,
  RoleStatus,
  RoleStatusCandidateCount,
} from "../../../Models";
import {
  CreateCandidateStatus,
  CreateMultipleCandidateStatus,
  GetCandidateForRole,
  GetStatusForRole,
  getCandidateVideoAnswers,
  paginationData,
  refreshRoleCandidateCache,
} from "../../../WebCalls";
import { useParams } from "react-router-dom";
import useApiToken from "../../../hooks/useApiToken";
import { getNextStatus, RoleStatusType } from "../../../Helpers";
import { Spinner } from "../../Shared/Spinner";
import ConfirmModal from "../../Shared/ConfirmModal";
import BulkActionModalDescription from "./BulkActionModalDescription";
import RoleTableHeader from "./RoleCandidatesHeader";
import RoleCandidatesList from "./RoleCandidatesList";
import Notification from "../../Shared/Notification";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import AddCandidateAudioQuestionModal from "../../Shared/Modals/AddCandidateAudioQuestion";

export interface CandidateAction extends Candidate {
  actions?: CandidateStatus[];
}

export type tab = {
  name: string;
  id: string;
  order: number;
  count: number;
};

export default function RoleCandidates({
  filterQuery,
  searchQuery,
  status,
  statusFilterIsAll,
  rankTypeId,
  handleStatusUpdate,
  selectedColumns,
}: {
  filterQuery: string;
  searchQuery: string;
  status: RoleStatusCandidateCount;
  statusFilterIsAll: boolean;
  rankTypeId: string;
  handleStatusUpdate: (status: RoleStatusCandidateCount) => void;
  selectedColumns: string[];
}) {
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [paginationData, setPaginationData] = useState<paginationData>();

  const [selectedCandidate, setSelectedCandidate] = useState<Candidate[]>([]);
  const [candidatesWithAction, setCandidatesWithAction] = useState<
    CandidateAction[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortQuery, setSortQuery] = useState<string>("");

  const [loading, setLoading] = useState(false);
  const [actionButtonsDisabled, setActionButtonsDisabled] = useState([
    false,
    false,
    false,
  ]);
  const [bulkActionIsDisqualify, setBulkActionIsDisqualify] = useState(false);
  const [bulkActionModal, setBulkActionModal] = useState(false);
  const [statusNotification, setStatusNotification] = useState(false);

  const [isAudioModalOpen, setIsAudioModalOpen] = useState<boolean>(false);

  const [colomns, setcolomns] = useState<number>(0);
  const [agebased, setagebased] = useState<boolean>(true);
  const [expbased, setexpbased] = useState<boolean>(false);

  useEffect(() => {
    if (agebased) {
      setexpbased(false);
    } else {
      setexpbased(true);
    }
  }, [agebased]);

  const token = useApiToken();
  const { roleId } = useParams();
  useEffect(() => {
    setCandidates([]);
    setCurrentPage(1);
  }, [filterQuery, sortQuery, searchQuery]);

  useEffect(() => {
    if (!roleId || !token) return;
    setLoading(true);
    GetCandidateForRole(
      roleId,
      token,
      filterQuery,
      1,
      searchQuery,
      sortQuery
    ).then((cd) => {
      setCandidates(cd.candidates);
      setPaginationData(cd.paginationMetadata);
      setLoading(false);
    });
  }, [roleId, token, filterQuery, searchQuery, sortQuery, rankTypeId]);

  useEffect(() => {
    if (!roleId || !token) return;
    if (currentPage === 1) return;
    GetCandidateForRole(
      roleId,
      token,
      filterQuery,
      currentPage,
      searchQuery,
      sortQuery
    ).then((cd) => {
      setCandidates((prev) => [...prev, ...cd.candidates]);
      setPaginationData(cd.paginationMetadata);
    });
  }, [
    token,
    roleId,
    currentPage,
    filterQuery,
    searchQuery,
    sortQuery,
    rankTypeId,
  ]);

  useEffect(() => {
    const actionCandidates: CandidateAction[] = selectedCandidate
      .map((sc) => {
        const nextStatus = getNextStatus(
          status.roleStatuses,
          sc.candidateStatuses
        );
        const actions =
          nextStatus &&
          nextStatus.map((ns) => {
            const roleStatus = status.roleStatuses.find(
              (rs) => rs.id === ns
            ) as RoleStatus;
            return {
              roleId: roleStatus.roleId,
              candidateId: sc.id,
              roleStatusId: roleStatus.id,
              date: new Date().toISOString(),
              email: true,
            };
          });
        return {
          ...sc,
          actions,
        };
      })
      .filter((c) => c.actions !== undefined);

    const notHaveDisqualify = !status.roleStatuses.some(
      (rs) => rs.name === RoleStatusType.rejected
    );

    if (actionCandidates.length === 0)
      setActionButtonsDisabled([true, true, false]);
    else if (notHaveDisqualify) setActionButtonsDisabled([false, true, false]);
    else setActionButtonsDisabled([false, false, false]);

    setCandidatesWithAction(actionCandidates);
  }, [selectedCandidate, status.roleStatuses]);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (scrollTop + windowHeight + 1 >= documentHeight)
        setCurrentPage((prev) =>
          !paginationData
            ? prev
            : prev >= paginationData.totalPages
              ? prev
              : prev + 1
        );
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [paginationData, candidates]);

  // useEffect(() => {

  //     const disqualifyStatus=status.roleStatuses.find(rs=>rs.name===RoleStatusType.rejected)
  //     if(!disqualifyStatus)

  // }, [candidatesWithAction]);

  const handleUpdateCandidates = (
    candidatesUpdate: CandidateStatus[],
    gradeStatusUpdate = false
  ) => {
    let updatedCandidates: Candidate[];
    if (gradeStatusUpdate) {
      updatedCandidates = candidates.map((c) => {
        if (c.id === candidatesUpdate[0].candidateId) {
          const statusUpdate = c.candidateStatuses.map((cs) => {
            if (
              cs.roleId === candidatesUpdate[0].roleId &&
              cs.roleStatusId === candidatesUpdate[0].roleStatusId
            )
              return candidatesUpdate[0];
            else return cs;
          });
          return {
            ...c,
            candidateStatuses: statusUpdate,
          };
        } else return c;
      });
    } else {
      if (statusFilterIsAll) {
        updatedCandidates = candidates.map((c) => {
          const statusUpdate = candidatesUpdate.find(
            (cu) => cu.candidateId === c.id
          );
          if (statusUpdate)
            return {
              ...c,
              latestStatusDate: statusUpdate.date,
              latestStatusId: statusUpdate.roleStatusId,
              candidateStatuses: [...c.candidateStatuses, statusUpdate],
            };
          else return c;
        });
      } else {
        updatedCandidates = candidates.filter((candidate) => {
          return !candidatesUpdate.some(
            (update) => update.candidateId === candidate.id
          );
        });
      }
    }
    setCandidates(updatedCandidates);
    setSelectedCandidate([]);

    if (!roleId || !token) return;
    GetStatusForRole(roleId, token).then((rs) => {
      handleStatusUpdate(rs);
    });
  };

  const handleBulkUpdate = async () => {
    if (!token) return;
    try {
      const css = candidatesWithAction.map((sc) => {
        return bulkActionIsDisqualify
          ? (sc.actions as CandidateStatus[])[1]
          : (sc.actions as CandidateStatus[])[0];
      });
      const candidateStatusUpdate = await CreateMultipleCandidateStatus(
        token,
        css
      );
      if (roleId) refreshRoleCandidateCache(roleId);
      handleUpdateCandidates(candidateStatusUpdate);
      setBulkActionModal(false);
      setStatusNotification(true);
    } catch (er) {
      setBulkActionModal(false);
      console.log(er);
    }
  };

  const handleMediaAnswerDownload = async () => {
    const candidateIds = selectedCandidate.map((c) => c.id);
    const res = await getCandidateVideoAnswers(candidateIds);
    if (res.length === 1) {
      const candidateEmail = res[0].email;

      const zip = new JSZip();
      const response = res[0].videoAnswers;
      response.forEach((response: any, index: number) => {
        const videoData = response.answerVideo;
        const responseFolderName = `Video response ${index + 1}`;
        const questionText = response.questionText;
        const responseFolder = zip.folder(
          `${candidateEmail}/${responseFolderName}`
        );

        responseFolder?.file(`${responseFolderName}.mp4`, videoData, {
          base64: true,
        });
        responseFolder?.file(`${responseFolderName}.txt`, questionText);
      });

      zip.generateAsync({ type: "blob" }).then((blob) => {
        saveAs(blob, `${candidateEmail}.zip`);
      });
    } else {
      const zip = new JSZip();
      const parentFolderName = "Candidates Video Responses";

      const parentFolder = zip.folder(parentFolderName);
      if (parentFolder !== null) {
        res.forEach((candidate) => {
          const candidateEmail = candidate.email;
          const candidateAnswers = candidate.videoAnswers;

          const candidateFolder = parentFolder.folder(candidateEmail);
          if (candidateFolder !== null) {
            candidateAnswers.forEach((response: any, index: number) => {
              const videoData = response.answerVideo;
              const responseFolderName = `Video response ${index + 1}`;
              const questionText = response.questionText;
              const responseFolder = candidateFolder.folder(responseFolderName);

              if (responseFolder !== null) {
                responseFolder.file(`${responseFolderName}.mp4`, videoData, {
                  base64: true,
                });
                responseFolder.file(`${responseFolderName}.txt`, questionText);
              }
            });
          }
        });

        zip.generateAsync({ type: "blob" }).then((blob) => {
          saveAs(blob, `${parentFolderName}.zip`);
        });
      }
    }
  };

  const handleAddAudioQuestion = () => {
    setIsAudioModalOpen(true);
  };

  const confirmAdd = async () => {
    setIsAudioModalOpen(false);
  };

  const cancelAdd = () => {
    setIsAudioModalOpen(false);
  };

  const isAudioModalClosed = () => {
    setIsAudioModalOpen(false);
  };

  return (
    <div className="min-h-full relative" id="candidates">
      <table className="min-w-full table-fixed divide-y">
        <RoleTableHeader
          candidates={candidates}
          selectedCandidate={selectedCandidate}
          actionButtonsDisabled={actionButtonsDisabled}
          handleSelectedCandidates={(candidates) => {
            setSelectedCandidate(candidates);
          }}
          handleSort={(query: string) => {
            setSortQuery(query);
          }}
          handleBulkActionIsDisqualify={(open) => {
            setBulkActionIsDisqualify(open);
          }}
          handleBulkActionModal={(open) => {
            setBulkActionModal(open);
          }}
          handleMediaAnswerDownload={handleMediaAnswerDownload}
          handleAddAudioQuestion={handleAddAudioQuestion}
          selectedColoumns={selectedColumns}
          setcolomns={setcolomns}
          colomns={colomns}
          setagebased={setagebased}
          agebased={agebased}
        />

        {loading ? (
          <tbody>
            <tr className="h-80">
              <td colSpan={11}>
                <Spinner height="h-10" width="w-10" />
              </td>
            </tr>
          </tbody>
        ) : candidates.length === 0 ? (
          <tbody>
            <tr className="h-80">
              <td
                colSpan={11}
                className="text-center mt-20 text-base leading-7 text-gray-600 dark:text-gray-400"
              >
                No candidates found{" "}
              </td>
            </tr>
          </tbody>
        ) : (
          <RoleCandidatesList
            selectedCandidate={selectedCandidate}
            handleSelectedCandidate={(candidate) => {
              setSelectedCandidate(candidate);
            }}
            handleUpdateCandidates={handleUpdateCandidates}
            candidates={candidates}
            status={status}
            colomns={colomns}
            agebased={agebased}
            expbased={expbased}
          />
        )}
      </table>

      <ConfirmModal
        open={bulkActionModal}
        setOpen={setBulkActionModal}
        title={
          bulkActionIsDisqualify
            ? `Disqualify ${selectedCandidate.length} candidate${
                selectedCandidate.length !== 1 ? "s" : ""
              }`
            : `Advance ${selectedCandidate.length} candidate${
                selectedCandidate.length !== 1 ? "s" : ""
              }`
        }
        description={
          <BulkActionModalDescription
            handleCandidateWithAction={(c) => {
              setCandidatesWithAction(c);
            }}
            candidatesWithAction={candidatesWithAction}
            roleStatuses={status.roleStatuses}
            setSelectedCandidate={setSelectedCandidate}
            bulkActionIsDisqualify={bulkActionIsDisqualify}
          />
        }
        onConfirm={handleBulkUpdate}
        type={bulkActionIsDisqualify ? "danger" : "info"}
      />

      <Notification
        show={statusNotification}
        setShow={setStatusNotification}
        type="success"
        description="Candidate statuses changed successfully."
        title="Statuses updated"
      />
      {isAudioModalOpen && (
        <AddCandidateAudioQuestionModal
          title=""
          content=""
          onAdd={confirmAdd}
          onCancel={cancelAdd}
          isModalClosed={isAudioModalClosed}
          size="large"
          selectedCandidateIds={selectedCandidate.map((e) => e.id)}
        />
      )}
    </div>
  );
}
