import React, { useEffect, useRef, useState } from "react";
import RecruitmentTimeline from "./RecruitmentTimeline";
import { Role, RoleStatusCandidateCount } from "../../../Models";
import { ArrowDownTrayIcon, EnvelopeIcon } from "@heroicons/react/20/solid";
import useApiToken from "../../../hooks/useApiToken";
import { GetCandidateCv } from "../../../WebCalls";
import { downloadCv } from "../../../Helpers";

type props = {
  openTimeline: boolean;
  setOpenTimeline: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirmTimeline: () => void;
  title: string;
  description: string;
  timelineData: any[];
  role: Role | undefined;
  status: RoleStatusCandidateCount;
  candidateStatus: any[];
  hoveredCandidate: any;
};
interface TimelineEvent {
  [key: string]: {
    date: string;
    color: string;
    radius: number;
    text: string;
    id: string;
    dependsOn: string;
  };
}

export default function RecruitmentTimelineModal(props: props) {
  const [timelineStatus, setTimelineStatus] = useState<string[]>([]);
  const [timelineData, setTimelineData] = useState<TimelineEvent[]>([]);
  const [expandData, setExpandData] = useState<string>("");

  const token = useApiToken();

  const [modalTop, setModalTop] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalRef.current) {
      const viewportHeight = window.innerHeight;
      const modalBottomPosition =
        modalRef.current.getBoundingClientRect().bottom + 220;
      if (modalBottomPosition > viewportHeight) {
        setModalTop(true);
      } else {
        setModalTop(false);
      }
    }
  }, [props.openTimeline]);

  useEffect(() => {
    const statusDetails =
      props.status.roleStatuses &&
      props.status.roleStatuses.map((s) => {
        return { name: s.name, order: s.order };
      });

    statusDetails.sort((a, b) => a.order - b.order);
    statusDetails.unshift({ name: "Job Posted", order: 0 });
    const sortedStatuses = statusDetails.map((s) => s.name);
    setTimelineStatus(sortedStatuses);
  }, [props.status]);

  useEffect(() => {
    initializeTimelineData();
    setExpandData("");
  }, [timelineStatus, props.candidateStatus, props.hoveredCandidate]);

  const initializeTimelineData = async () => {
    if (
      timelineStatus.length > 0 &&
      props.candidateStatus &&
      props.candidateStatus.length > 0
    ) {
      const updatedTimelineData: TimelineEvent[] = props.candidateStatus
        .map((event: any) => {
          let color = "gray";
          if (event.name.toLowerCase() === "assessment") {
            color = "blue";
            const assessmentDate = new Date(event.date);
            const applicationReceivedDate = new Date(assessmentDate.getTime());
            const assessmentLinkSentDate = new Date(
              assessmentDate.getTime() + 60000
            );

            const ApplicationReceived: TimelineEvent = {
              "Application Received": {
                date: applicationReceivedDate.toISOString(),
                color: "orange",
                radius: 50,
                text: "Application Received",
                id: "Application Received",
                dependsOn: event.name,
              },
            };

            const AssessmentLinkSent: TimelineEvent = {
              "Assessment Link Sent": {
                date: assessmentLinkSentDate.toISOString(),
                color: "rgb(0, 153, 255)",
                radius: 50,
                text: "Assessment Link Sent",
                id: "Assessment Link Sent",
                dependsOn: event.name,
              },
            };
            const hasInterview = props.candidateStatus.some(
              (status) =>
                status.name === "Interview" ||
                status.name === "Technical Interview"
            );
            if (props.hoveredCandidate?.score && !hasInterview) {
              let AssessmentDone: TimelineEvent = {
                "Assessment Done": {
                  date: new Date(
                    assessmentLinkSentDate.getTime() + 60000
                  ).toISOString(),
                  color: "orange",
                  radius: 50,
                  text: `Score - ${props.hoveredCandidate?.score}`,
                  id: "Assessment Done",
                  dependsOn: event.name,
                },
              };
              return [ApplicationReceived, AssessmentLinkSent, AssessmentDone];
            }

            return [ApplicationReceived, AssessmentLinkSent];
          } else if (
            event.name.toLowerCase() === "interview" ||
            event.name.toLowerCase() === "technical interview"
          ) {
            const interviewDate = new Date(event.date);
            const interviewLinkSentDate = new Date(
              interviewDate.getTime() + 60000
            );

            const InterviewLinkSent: TimelineEvent = {
              "Interview Link Sent": {
                date: interviewLinkSentDate.toISOString(),
                color: "rgb(0, 153, 255)",
                radius: 50,
                text: "Interview Link Sent",
                id: "Interview Link Sent",
                dependsOn: event.name,
              },
            };
            let AssessmentDone: TimelineEvent = {};
            if (props.hoveredCandidate?.score) {
              AssessmentDone = {
                "Assessment Done": {
                  date: interviewDate.toISOString(),
                  color: "orange",
                  radius: 50,
                  text: `Score - ${props.hoveredCandidate?.score}`,
                  id: "Assessment Done",
                  dependsOn: event.name,
                },
              };

              return [AssessmentDone, InterviewLinkSent];
            }

            return [InterviewLinkSent];
          } else if (event.name.toLowerCase() === "assignment") {
            const interviewDoneDate = new Date(event.date);
            const assignmentLinkSent = new Date(
              interviewDoneDate.getTime() + 60000
            );

            const TechnicalInterviewDone: TimelineEvent = {
              "Interview Done": {
                date: event.date,
                color: "brown",
                radius: 50,
                text: "Interview Done",
                id: "Interview Done",
                dependsOn: event.name,
              },
            };

            const AssignmentLinkSent: TimelineEvent = {
              "Assignment Link Sent": {
                date: assignmentLinkSent.toISOString(),
                color: "rgb(0, 153, 255)",
                radius: 50,
                text: "Assignment Link Sent",
                id: "Assignment Link Sent",
                dependsOn: event.name,
              },
            };

            if (props.hoveredCandidate?.assignmentSubmission) {
              const AssignmentSubmitted: TimelineEvent = {
                "Assignment Submitted": {
                  date: props.hoveredCandidate?.assignmentSubmission
                    .submissionDate,
                  color: "orange",
                  radius: 50,
                  text: "Assignment Submitted",
                  id: "Assignment Submitted",
                  dependsOn: event.name,
                },
              };

              return [
                TechnicalInterviewDone,
                AssignmentLinkSent,
                AssignmentSubmitted,
              ];
            }

            return [TechnicalInterviewDone, AssignmentLinkSent];
          } else if (event.name.toLowerCase() === "offered") {
            const offeredDate = new Date(event.date);

            const finalInterviewDoneDate = new Date(offeredDate.getTime());

            const FinalInterviewDone: TimelineEvent = {
              "Final Interview Done": {
                date: finalInterviewDoneDate.toISOString(),
                color: "brown",
                radius: 50,
                text: "Final Interview Done",
                id: "Final Interview Done",
                dependsOn: event.name,
              },
            };
            const Offered: TimelineEvent = {
              Offered: {
                date: offeredDate.toISOString(),
                color: "blue",
                radius: 50,
                text: "Offered",
                id: "Offered",
                dependsOn: event.name,
              },
            };

            return [FinalInterviewDone, Offered];
          } else if (event.name.toLowerCase() === "disqualified") {
            const disqualifiedDate = new Date(event.date);
            const interviewDoneDate = new Date(disqualifiedDate.getTime());

            const Disqualified: TimelineEvent = {
              Disqualified: {
                date: disqualifiedDate.toISOString(),
                color: "red",
                radius: 50,
                text: "Disqualified",
                id: "Disqualified",
                dependsOn: event.name,
              },
            };
            const hasFinalInterview = props.candidateStatus.some(
              (status) =>
                status.name === "Final Interview" ||
                status.name === "Final Interview"
            );
            if (hasFinalInterview) {
              const FinalInterviewDone: TimelineEvent = {
                "Final Interview Done": {
                  date: interviewDoneDate.toISOString(),
                  color: "brown",
                  radius: 50,
                  text: "Final Interview Done",
                  id: "Final Interview Done",
                  dependsOn: event.name,
                },
              };

              return [FinalInterviewDone, Disqualified];
            }
            return [Disqualified];
          } else {
            color =
              timelineStatus.includes(event.name) ||
              event.name === "roleCreated"
                ? "rgb(0, 153, 255)"
                : "gray";
            return {
              [event.name]: {
                date: event.date,
                color: event.name === "Disqualified" ? "red" : color,
                radius: 50,
                text:
                  event.name.toLowerCase() === "final interview"
                    ? "Final Interview Link Sent"
                    : event.name,
                id: event.name,
                dependsOn: event.name,
              },
            };
          }
        })
        .flat();
      setTimelineData(updatedTimelineData);
    }
  };

  const handleCvDownload = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (!token || !props.hoveredCandidate.id) return;
    const name =
      props.hoveredCandidate.firstName + "_" + props.hoveredCandidate.lastName;
    downloadCv(token, props.hoveredCandidate.id, name);
  };

  const getExpandedData = (id: string) => {
    setExpandData(id);
  };

  return (
    <div
      onMouseLeave={() => props.setOpenTimeline(false)}
      ref={modalRef}
      className={`absolute ${
        modalTop ? "bottom-4" : "top-4"
      } left-0 rounded-2xl min-w-[40rem] max-w-[45rem] w-fit sm:h-auto !z-[999] border border-gray-200 text-gray-600 dark:border-gray-500 dark:text-gray-300`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="flex shadow-lg rounded-b-2xl w-full h-screen sm:h-full items-end justify-end sm:p-4 lg:p-0 text-center sm:items-center overflow-hidden ">
        <div className="bg-white rounded-2xl h-full w-full   overflow-y-scroll md:overflow-hidden dark:bg-darkbglight">
          <div className="z-10 sticky top-0 rounded-xl border-2 m-1 sm:flex sm:items-start bg-white dark:bg-darkbglight px-9 py-4 shadow-sm border-b-2 dark:shadow-white dark:border-gray-500">
            <div className="flex items-center justify-between font-medium w-full">
              <div className="text-sm text-black dark:text-gray-200 max-w-28 truncate">
                {props.hoveredCandidate?.firstName}{" "}
                {props.hoveredCandidate?.lastName}
              </div>
              <div className="">
                {props.hoveredCandidate?.assignmentSubmission ? (
                  <a
                    href={props.hoveredCandidate.assignmentSubmission.assignmentLink}
                    target="blank"
                    className="font-medium text-entntblue hover:text-entntorange"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    GitHub
                  </a>
                ) : (
                  "-"
                )}
              </div>
              <div className="flex flex-row gap-3">
                <a href={`mailto:${props.hoveredCandidate?.email}`}>
                  <EnvelopeIcon
                    className="h-6 text-entntblue hover:text-entntorange cursor-pointer"
                    title={props.hoveredCandidate?.email}
                  />
                </a>
                <ArrowDownTrayIcon
                  className="h-6 text-entntblue hover:text-entntorange cursor-pointer"
                  title="Download Resume"
                  href={`mailto:${props.hoveredCandidate?.email}`}
                  onClick={(e) => handleCvDownload(e)}
                />
              </div>
            </div>
          </div>
          <div className="h-full px-4 flex items-center justify-center mt-4">
            <RecruitmentTimeline
              timelineData={timelineData}
              timelineStatus={timelineStatus}
              getExpandedData={getExpandedData}
            />
          </div>
          {expandData && (
            <div className="h-auto px-10 py-4 border-2 dark:border-0 mt-1 text-gray-900 dark:text-gray-300">
              {expandData === "Assessment Done" && (
                <div className="text-left text-xs">
                  <div className="">
                    Candidate Grade : {props.hoveredCandidate?.score}
                  </div>
                  <div className="mt-1">
                    Candidate Performance : Top {props.hoveredCandidate?.score}
                  </div>
                </div>
              )}
              {expandData === "Assignment Submitted" && (
                <div className="text-left text-sm flex flex-row">
                  <div className="flex flex-col justify-between">
                    <div>
                      <div className="sm:min-w-56 mt-1">
                        Assignment Submission Link -{" "}
                        <a
                          href={`${props.hoveredCandidate?.assignmentSubmission?.assignmentLink}`}
                          className="text-blue-400"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Link
                        </a>
                      </div>
                    </div>
                  </div>
                  {props.hoveredCandidate?.assignmentSubmission?.comments
                    .length > 0 && (
                    <div className="flex-grow bg-gray-200 ml-6 px-2 py-1 h-auto rounded-lg text-gray-700">
                      {props.hoveredCandidate?.assignmentSubmission?.comments}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
